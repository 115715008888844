import { v4 as uuidv4 } from 'uuid'

import {
  BoardDetails,
  BoardDetailsSchema,
  BoardDetailsWithRefsSchema,
  BoardEventsSchema,
  BoardSummaryListSchema,
  BoardSummaryPage,
  BoardSummaryPageSchema,
  BoardSummarySchema,
  LocationType,
  SourceBoardSchema,
  emptyBoardSummaryPage,
  parseFactory,
} from '~/endpoints/model'
import { getAuthClient } from '~/utils/apiClient'
import type { SSRAuthToken } from '~/utils/ssr'
import { LocationPayloadType } from '~/utils/types'

const boardDetailsParser = parseFactory(BoardDetailsSchema, 'BoardDetails')
const boardDetailsWithRefsParser = parseFactory(BoardDetailsWithRefsSchema, 'BoardDetailsWithRefs')
const boardSummaryListParser = parseFactory(BoardSummaryListSchema, 'BoardSummaryList')
const boardSummaryParser = parseFactory(BoardSummarySchema, 'BoardSummary', true)
const sourceBoardParser = parseFactory(SourceBoardSchema, 'SourceBoard')
const boardEventParser = parseFactory(BoardEventsSchema, 'BoardEvents')
const boardSummaryPageParser = parseFactory(BoardSummaryPageSchema, 'BoardSummaryPage')

export async function fetchBoardPage(
  page?: number,
  size?: number,
  auth?: SSRAuthToken
): Promise<BoardSummaryPage> {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/for-index`, {
    params: { page: page ?? 0, size: size ?? 100 },
  })
  return boardSummaryPageParser(res.data)
}

export const fetchBoardDetailsByToken = async (boardToken: string, auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/${boardToken}`)
  return boardDetailsParser(res.data)
}

export const fetchBoardDetailRefsByToken = async (
  boardToken: string,
  isForEdit: boolean,
  auth?: SSRAuthToken
) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/${boardToken}/with-refs`)
  const boardDetailsWithRefs = boardDetailsWithRefsParser(res.data)
  if (isForEdit) {
    const nodes = boardDetailsWithRefs.nodes.slice(-1)
    if (nodes && nodes.length > 0 && nodes[0].type == 'place') {
      /*
      special handling of place nodes, if they are the last node in list of nodes,
      as editor js triggers a block-change event, if its not a text node in the end of the list
      so adding a dummy text node, only when there is place node in the end of list, this only
      happens when we do add place to guide feature
       */
      const uuid = uuidv4()
      boardDetailsWithRefs.blocks.push(uuid)
      boardDetailsWithRefs.nodes.push({
        uid: uuid,
        text: '',
        style: 'body1',
        type: 'text',
      })
    }
  }
  return boardDetailsWithRefs
}

export const fetchBoardSummaryByToken = async (boardToken: string, auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/${boardToken}/summary`)
  return boardSummaryParser(res.data)
}

export const fetchSourceBoardByToken = async (boardToken: string, auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/${boardToken}/summary`)
  return sourceBoardParser(res.data)
}

export const fetchAllPublishedBoardByUser = async (userName: string, auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/published-by/${userName}`, {
    params: { size: 100 }, // no paginated results just yet
  })
  return boardSummaryPageParser(res.data)
}

export const fetchAllSavedBoardByUser = async (
  userName: string,
  auth?: SSRAuthToken,
  size?: number
) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/saved-by/${userName}`, {
    params: { size: size ?? 100 }, // no paginated results just yet
  })
  return boardSummaryPageParser(res.data)
}

export const fetchFeaturedContent = async (): Promise<BoardSummaryPage> => {
  const featuredContentUser = process.env.NEXT_PUBLIC_FEATURED_CONTENT_USERNAME
  // Prevent an invalid API call in environments that don't have a featured content user setup.
  if (!featuredContentUser) {
    return { ...emptyBoardSummaryPage }
  }
  return fetchAllSavedBoardByUser(featuredContentUser, undefined, 12)
}

export const fetchFeaturedBoardByToken = async (auth?: SSRAuthToken) => {
  const featuredBoardToken = process.env.FEATURED_BOARD_TOKEN ?? ''
  return fetchBoardSummaryByToken(featuredBoardToken, auth)
}

export const fetchSubscriptionUpdates = async (limit: number, auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get(`/boards/v2/events`, {
    params: { limit },
  })
  return boardEventParser(res.data)
}

export const fetchAllBoardsForUser = async (auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get('/boards/v2/')
  return boardSummaryListParser(res.data)
}

export const fetchMyBoards = async (auth?: SSRAuthToken) => {
  const httpClient = getAuthClient(auth)
  const res = await httpClient.get('/boards/v2/mine')
  return boardSummaryListParser(res.data)
}

export const createBoard = async () => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.post('/boards/v2')
  return boardDetailsParser(res.data)
}

export const getBoardDetails = async (id: string, auth?: SSRAuthToken) => {
  const httpsClient = getAuthClient(auth)
  const res = await httpsClient.get(`/boards/${id}`)
  return boardDetailsParser(res.data)
}

export const deleteBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.delete(`/boards/v2/${token}`)
  return res.data
}

export const favBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.post(`/boards/v2/${token}/pinned`)
  return res.data
}

export const unFavBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.delete(`/boards/v2/${token}/pinned`)
  return res.data
}

export const saveBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.post(`/boards/v2/${token}/saved`)
  return res.data
}

export const unSaveBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.delete(`/boards/v2/${token}/saved`)
  return res.data
}

export const duplicateBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.post(`/boards/v2/${token}/copy`)
  return boardDetailsParser(res.data)
}

export const personalizeBoard = async (token: string) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.post(`/boards/v2/${token}/personalize`)
  return boardDetailsParser(res.data)
}

export const saveBoardDetails = async ({
  token,
  name,
  description,
  coverImage,
  coverVideo,
  blocks,
  nodes,
  lockVersion,
  force,
}: BoardDetails & { force?: boolean }) => {
  const httpClient = getAuthClient()
  const requestPayload = {
    name,
    description,
    coverImage,
    coverVideo,
    blocks,
    nodes,
    lockVersion,
    force,
  }

  const res = await httpClient.put(`/boards/v2/${token}?force=${force}`, requestPayload)
  return res.data
}

export const updateBoardSettings = async ({
  token,
  acceptTips,
  price,
  publish,
  saleType = 'content',
  destinations = [],
  designedForTypes,
  coverVideo,
  themeTypes,
  isDesignedForAnyone = false,
  isForAnyMonth = false,
  months,
  guideType,
}: {
  token: string
  acceptTips: boolean
  price: number | null
  publish: boolean
  saleType: 'content' | 'service'
  destinations: (LocationPayloadType | LocationType)[]
  designedForTypes: string[]
  coverVideo: string | null
  themeTypes: string[]
  isDesignedForAnyone: boolean
  isForAnyMonth: boolean
  months: string[]
  guideType: string | undefined
}) => {
  const httpClient = getAuthClient()
  const requestPayload = {
    acceptTips,
    price,
    publish,
    saleType,
    destinations,
    designedFor: designedForTypes,
    coverVideo,
    themes: themeTypes,
    anyone: isDesignedForAnyone,
    anytime: isForAnyMonth,
    bestTime: months,
    guideType,
  }
  const res = await httpClient.put(`/boards/v2/${token}/settings`, requestPayload)
  return boardDetailsWithRefsParser(res.data)
}

export const appendPlaces = async (token: string, placeIds: number[]) => {
  const httpsClient = getAuthClient()
  const res = await httpsClient.post(`/boards/v2/${token}/all-places`, placeIds)
  return boardDetailsParser(res.data)
}

export const extractYtTranscript = async (ytUrl: string) => {
  const httpsClient = getAuthClient(undefined, true)
  const endpoint = `/api/yt-transcript?video=${ytUrl}`
  try {
    const response = await httpsClient.get<string>(endpoint)
    if (response.status == 200) {
      return response.data
    }
    console.warn(
      'Unable to fetch transcript: [status=%s, message=%s]',
      response.status,
      response.statusText
    )
  } catch (error) {
    console.warn('Error fetch transcript: %s', error)
  }
  throw new Error('Failed to fetch video transcript')
}
