import { createStyles } from '@mantine/core'

export const thatchDialogStyle = createStyles(
  (theme, { height, width }: { height?: number | string; width?: number | string }) => ({
    content: {
      width: width ?? 918,
      height: height ?? 550,
      // Somehow padding style doesn't apply to modal without "!important"
      padding: '45px 31px 45px 40px !important',
      overflowY: 'auto',
      maxHeight: '100vh',
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        width: '100%',
        height: '100%',
        padding: '20px 11px 20px 20px !important',
      },
      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        maxHeight: '100vh',
      },
    },
    modalRoot: {
      zIndex: 10005, // take into account TopNav zIndex
    },
    header: {
      position: 'unset',
      padding: 0,
      marginBottom: 40,
      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        marginBottom: 16,
      },
    },
    title: {
      flex: 1,
    },
    modalCloseButton: {
      color: theme.colors.appBlack,
      borderRadius: 24,
      width: 48,
      height: 48,
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
      '>svg': {
        width: 22,
        height: 22,
        color: theme.colors.appBlack,
      },
    },
  })
)

export const newThatchDialogStyle = createStyles(
  (
    theme,
    {
      width,
      height,
      withCloseButton = true,
    }: { width?: number | string; height?: number | string; withCloseButton?: boolean }
  ) => ({
    modalRoot: {
      zIndex: 10005,
    },
    modalHeader: {
      padding: 26,
      margin: 0,
      borderBottom: `1px solid rgba(0, 0, 0, 0.25)`,
      position: 'sticky',

      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        padding: '20px 24px',
      },
    },
    modalTitle: {
      flex: 1,
      whiteSpace: 'nowrap',

      marginRight: withCloseButton ? undefined : 0,
      textAlign: withCloseButton ? undefined : 'center',
    },
    body: {
      height: 'calc(100vh - 128px)',
      overflowY: 'auto',
    },
    modalCloseButton: {
      color: theme.colors.appBlack,
      width: 30,
      height: 30,
      outline: 'none',

      '&:focus': {
        outline: 'none',
      },

      '>svg': {
        width: 30,
        height: 30,
        color: theme.colors.appBlack,
      },
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      width: width ?? 918,
      height: height ?? 550,
      overflowY: 'auto',

      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        width: '100%',
        height: '100%',
      },
    },
    modalBody: {
      flex: 1,
      padding: '0px !important',
    },

    modalContainer: {
      padding: '32px !important',

      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        padding: '24px 16px !important',
      },
    },
    modalFooter: {
      position: 'sticky',
      borderTop: `1px solid ${theme.colors.appBlack}`,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.appWhite,
      zIndex: 11,
    },
  })
)
