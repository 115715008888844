import Script from "next/script";

export function AdPixels() {
    return (<>
        <Script id="reddit-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_fujgjgtv1wgt');rdt('track', 'PageVisit');
            `}}
        />
        <Script id="facebook-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1112227600507777'); 
fbq('track', 'PageView');
                  `
            }}
        />
        <noscript>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img height="1" width="1" src="https://www.facebook.com/tr?id=1112227600507777&ev=PageView&noscript=1" alt="fb"/>
        </noscript>
    </>)
}