import { useEffect } from 'react'
import { useAnalytics } from '~/context'
import { useCurrentAuthState } from '~/context'

export const UserIdentify = () => {
  const { userProfile: userData, email } = useCurrentAuthState()
  const thatchSegment = useAnalytics()

  useEffect(() => {
    if (email && userData?.uid) {
        console.log('--User Identified--')
        thatchSegment.identitfyUser({ userId: userData?.uid, email: email })
    }
  }, [email, userData, thatchSegment])
  return null
}
