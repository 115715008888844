var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ec4638287075046106644ed9cd95e496e81324a0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
// https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/tree-shaking/
// https://github.com/getsentry/sentry-javascript/issues/2707
import {
  Breadcrumbs,
  BrowserClient,
  FunctionToString,
  GlobalHandlers,
  HttpContext,
  InboundFilters, // LinkedErrors,
  TryCatch, // Dedupe,
  defaultStackParser,
  getCurrentHub,
  makeFetchTransport,
} from '@sentry/browser'
import axios from 'axios'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const client = new BrowserClient({
  // all options you normally pass to Sentry.init
  dsn: SENTRY_DSN || 'https://aa8838fe0d57479fb221bd0a8d541a0c@o365582.ingest.sentry.io/6653371',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,

  enabled: process.env.NEXT_PUBLIC_THATCH_ENV !== 'development',

  environment: process.env.NEXT_PUBLIC_THATCH_ENV ?? 'unknown',

  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  // Only the integrations listed here will be used
  integrations: [
    new Breadcrumbs(), // ~7kB unminified
    // new Dedupe(), // ~5kB unminified
    new FunctionToString(), // ~1kB unminified
    new GlobalHandlers(), // ~7kB unminified
    new HttpContext(), // ~2kB unminified
    new InboundFilters(), // ~6kB unminified
    // new LinkedErrors(), // ~2kB unminified
    new TryCatch(), // ~9kB unminified]
  ],

  // disable console log & info breadcrumbs
  beforeBreadcrumb(breadcrumb, _hint) {
    return breadcrumb.category === 'console' &&
      (breadcrumb.level == 'log' || breadcrumb.level == 'info')
      ? null
      : breadcrumb
  },

  // add axios error config to logs
  beforeSend(event, hint) {
    const error = hint.originalException
    if (axios.isAxiosError(error)) {
      event.extra = { ...error.config }
    }
    return event
  },
})

getCurrentHub().bindClient(client)
